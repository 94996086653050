import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 128px;
  padding-bottom: 83px;
  background: ${color.white};
  text-align: left;

  ${MEDIA.MIN_TABLET`
    padding-top: 200px;
  `};
`;

export const Title = styled.h2`
  font-family: ${font.primaryBold};
  font-size: 40px;
  line-height: 48px;
  width: 100%;
  margin-bottom: 100px;
  text-align: center;

  ${MEDIA.MIN_TABLET`
    margin-bottom: 70px;
  `};
`;

export const Pick = styled.button`
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: none;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PicksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-row-gap: 51px;
  ${MEDIA.MIN_TABLET`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${MEDIA.TABLET`
    margin-bottom: 50px;
  `};
`;

export const Icon = styled.img`
  margin-bottom: 30px;
`;

export const Caption = styled.div`
  font-family: ${font.primary};
  text-align: center;
  size: 18px;
  line-height: 26px;
  strong {
    font-weight: bold;
  }
`;

export const Image = styled(Img)`
  margin-bottom: 24px;
  ${MEDIA.TABLET`
    width: 148px !important;
    height: 224px !important;
  `};
`;

export const Placeholder = styled.div`
  width: 173px;
  height: 261px;
  background: lightgrey;
  margin-bottom: 24px;
  ${MEDIA.TABLET`
    width: 148px;
    height: 224px;
  `};
`;
