import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'components/layout';
import Hero from '../components/picks/hero';
import List from '../components/picks/list';
import StoreMinimalCtaSection from 'components/storeCtaMinimalSection';

const query = graphql`
  query PicksQuery {
    allContentfulBook(sort: { fields: [pickDate], order: DESC }) {
      edges {
        node {
          id
          titleNode: title {
            title
          }
          author
          purchaseLinks {
            name
            url {
              url
            }
          }
          coverImage {
            title
            fluid(maxWidth: 162, quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
              base64
              tracedSVG
              srcWebp
              srcSetWebp
            }
            fixed(width: 173, height: 261) {
              width
              height
              src
              srcSet
              base64
              tracedSVG
              srcWebp
              srcSetWebp
            }
          }
          purchaseLinks {
            url {
              url
            }
            os
          }
          pickCollection
          pickDate
          season
        }
      }
    }
  }
`;

const Picks = () => {
  const picks = useStaticQuery(query).allContentfulBook.edges;

  const heroPicks = picks.slice(0, 2);
  const listPicks = picks.slice(2);

  return (
    <Layout>
      <Hero picks={heroPicks} />
      <List picks={listPicks} />
      <StoreMinimalCtaSection />
    </Layout>
  );
};

export default Picks;
