import firebase from 'firebase';
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { ContentWrapper } from '../../../global.css';
import Tag from 'components/tag';
import {
  Container,
  Title,
  Pick,
  PicksWrapper,
  Image,
  Placeholder,
} from './index.css';
import PicksDetails from '../details';

const query = graphql`
  query PicksListQuery {
    picksJson {
      list {
        title
      }
    }
  }
`;

const PicksList = ({ picks }) => {
  const [pickedBook, setPickedBook] = useState(null);
  const data = useStaticQuery(query).picksJson.list;

  return (
    <>
      <Container>
        <ContentWrapper padding={30}>
          <Title>{data.title}</Title>
          <PicksWrapper>
            {picks.map(({ node }) => (
              <Pick
                key={node.id}
                onClick={() => {
                  setPickedBook(node);
                  firebase.analytics().logEvent('book_details_open');
                }}
              >
                {node.coverImage ? (
                  <Image
                    fixed={node.coverImage.fixed}
                    alt={node.coverImage.title}
                  />
                ) : (
                  <Placeholder />
                )}
                <Tag
                  collection={node.pickCollection}
                  date={node.pickDate}
                  season={node.season}
                />
              </Pick>
            ))}
          </PicksWrapper>
        </ContentWrapper>
      </Container>
      <PicksDetails book={pickedBook} resetBook={() => setPickedBook(null)} />
    </>
  );
};

PicksList.propTypes = {
  picks: PropTypes.array,
};

export default PicksList;
