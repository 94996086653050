import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 128px;
  padding-bottom: 83px;
  text-align: left;
  background: linear-gradient(178.88deg, #ff9966 0.96%, rgba(252, 213, 229, 0.9) 64.29%, #eeece0 122.56%);

  ${MEDIA.MIN_TABLET`
    padding-top: 200px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `};
`;

export const TextWrapper = styled.div`
  ${MEDIA.MIN_TABLET`
    padding-right: 61px;
  `};
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.1em;
`;

export const Title = styled.h1`
  font-family: ${font.secondary};
  font-size: 40px;
  line-height: 45px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 30px;
  white-space: pre-wrap;

  ${MEDIA.MIN_TABLET`
    font-size: 60px;
    line-height: 60px;
  `};
`;

export const Pick = styled.button`
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: none;
  &:hover {
    cursor: pointer;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled(Img)`
  margin-bottom: 24px;
  min-width: 100%;
  ${MEDIA.MIN_TABLET`
    min-width: 162px;
  `};
`;

export const PickWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 61px;
`;

export const Placeholder = styled.div`
  min-width: 100%
  width: 100%;
  height: 100%;
  background: lightgrey;
  margin-bottom: 24px;
  ${MEDIA.MIN_TABLET`
    min-width: 162px;
  `};
`;
