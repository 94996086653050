import firebase from 'firebase';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Tag from 'components/tag';
import { ContentWrapper } from '../../../global.css';
import {
  Container,
  Title,
  Content,
  TextWrapper,
  Subtitle,
  Image,
  Pick,
  PickWrapper,
  Placeholder,
  FlexContainer,
} from './index.css';
import PicksDetails from '../details';

const query = graphql`
  query PicksHeroQuery {
    picksJson {
      hero {
        title
        subtitle
      }
    }
  }
`;

const PicksHero = ({ picks }) => {
  const [pickedBook, setPickedBook] = useState(null);
  const data = useStaticQuery(query).picksJson.hero;

  return (
    <>
      <Container>
        <ContentWrapper padding={30}>
          <Content>
            <TextWrapper>
              <Subtitle>{data.subtitle}</Subtitle>
              <Title>{data.title}</Title>
            </TextWrapper>
            <PickWrapper>
              {picks.map(({ node }) => (
                <Pick
                  key={node.id}
                  onClick={() => {
                    setPickedBook(node);
                    firebase.analytics().logEvent('book_details_open');
                  }}
                >
                  <FlexContainer>
                    {node.coverImage ? (
                      <Image fluid={node.coverImage.fluid} alt={node.coverImage.title} />
                    ) : (
                      <Placeholder />
                    )}
                    <Tag collection={node.pickCollection} date={node.pickDate} season={node.season} />
                  </FlexContainer>
                </Pick>
              ))}
            </PickWrapper>
          </Content>
        </ContentWrapper>
      </Container>
      <PicksDetails book={pickedBook} resetBook={() => setPickedBook(null)} />
    </>
  );
};

PicksHero.propTypes = {
  picks: PropTypes.array,
};

export default PicksHero;
